<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Account Plans')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    />
    <el-button
      v-show="!this.canWrite()"
      :disabled="!this.canWrite()"
      class="createBtn"
      @click.stop="createContentItem"
      >{{
        __("Assign :plan_filter Plan", { plan_filter: planFilter })
      }}</el-button
    >
    <el-dropdown
      v-show="this.canWrite()"
      split-button
      type="primary"
      class="createPlanDropDown"
      @click.stop="createPlanItem(planFilter)"
      @command="createPlanItem"
    >
      {{ __("Assign :plan_filter Plan", { plan_filter: planFilter }) }}
      <el-dropdown-menu slot="dropdown" :disabled="!this.canWrite()">
        <el-dropdown-item command="IVA" :disabled="!this.canWrite()">{{
          __("Assign IVA Plan")
        }}</el-dropdown-item>
        <el-dropdown-item command="QforMe Standard" :disabled="!this.canWrite()"
          >{{ __("Assign QforMe Standard Plan") }}
        </el-dropdown-item>
        <el-dropdown-item command="QforMe Premium" :disabled="!this.canWrite()"
          >{{ __("Assign QforMe Premium Plan") }}
        </el-dropdown-item>
        <el-dropdown-item
          command="Predictive Dialer"
          :disabled="!this.canWrite()"
          >{{ __("Assign Predictive Dialer Plan") }}
        </el-dropdown-item>
        <el-dropdown-item command="Biometrics" :disabled="!this.canWrite()"
          >{{ __("Assign Biometrics Plan") }}
        </el-dropdown-item>
        <el-dropdown-item command="DVA" :disabled="!this.canWrite()"
          >{{ __("Assign DVA Plan") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div style="padding: 0 36px">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="acPlanTable"
            v-loading="isLoading"
            :data="acPlans"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            highlight-current-row
            class="list-table"
          >
            <el-table-column :label="__('Account Name')">
              <template slot-scope="scope">
                <span>{{ scope.row.ac_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Business Plan')">
              <template slot-scope="scope">
                <span>{{ scope.row.bp_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Allocated Unit')">
              <template slot-scope="scope">
                <span>{{ scope.row.allocated_unit }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { EventBus } from "@/EventBus";

export default {
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      search: "",
      filters: ["ac_plan_id"],
      sortByColumns: ["account~ac_id|ac_name", "ac_plan_id"],
      navItems: [
        {
          label: "IVA",
          displayText: __("IVA"),
          active: true
        },
        {
          label: "QforMe Standard",
          displayText: __("QforMe Standard"),
          active: false
        },
        {
          label: "QforMe Premium",
          displayText: __("QforMe Premium"),
          active: false
        },
        {
          label: "Predictive Dialer",
          displayText: __("Predictive Dialer"),
          active: false
        },
        {
          label: "Biometrics",
          displayText: __("Biometrics"),
          active: false
        },
        {
          label: "DVA",
          displayText: __("DVA"),
          active: false
        }
      ]
    };
  },

  computed: {
    ...mapState("acplans", {
      acPlans: state => state.acPlans,
      isLoading: state => state.isLoading,
      planFilter: state => state.planFilter
    })
  },

  methods: {
    ...mapActions("acplans", {
      contentAPI: "getAcPlans",
      deleteContentMethod: "deleteAcPlan",
      undoDeleteContent: "undoDeleteAcPlan",
      setBusinessPlanFilter: "setBusinessPlanFilter"
    }),

    handleNavItemChanged(navItem) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
      this.setBusinessPlanFilter(navItem.label);
      EventBus.$emit("list-changed");
    },

    createPlanItem(planType) {
      this.$emit("open-create-modal", planType);
      EventBus.$emit("open-create-modal");
    },
    handleClear() {
      this.$refs.acPlanTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          this.$refs.acPlanTable.setCurrentRow(this.acPlans[val]);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.createPlanDropDown {
  position: absolute;
  top: 25px;
  right: 301px;
  border: none;
  color: white;
  background: $content-theme-color;
  border-radius: 6px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
